<template>
  <div class="fex">
    <el-row class="fex-container">
      <el-col :span="6" class="fex-container-money">
        <p class="fex-container-title">可索取发票金额</p>
        <p class="fex-container-price">¥ <span>{{(99999 / 100) | F1000}} <span style="font-size: 16px;margin-left: 0">元</span></span></p>
        <p class="fex-container-remark">
          <el-icon type="question-circle"/>
          仅实际消费金额可以参与开票
        </p>
        <el-button type="primary">索取发票</el-button>
      </el-col>
      <el-col :span="16" class="fex-container-information">
        <p class="fex-container-title">发票信息
          <el-icon type="form" :style="{fontSize: '16px'}"/>
        </p>
        <el-row>
          <el-col :span="12">
            <p class="fex-container-item">
              <span class="label">发票抬头</span>
              <span class="value">{{fex.invoiceTitle}}</span>
            </p>

            <p class="fex-container-item">
              <span class="label">发票类型</span>
              <span class="value">{{fex.invoiceType}}</span>
            </p>

            <p class="fex-container-item">
              <span class="label">税务登记证号</span>
              <span class="value">{{fex.invoiceNumber}}</span>
            </p>
          </el-col>
          <el-col :span="12">
            <p class="fex-container-item">
              <span class="label">开户银行名称</span>
              <span class="value">{{fex.bank}}</span>
            </p>

            <p class="fex-container-item">
              <span class="label">基本开户账号</span>
              <span class="value">{{fex.bankNumber}}</span>
            </p>
            <p class="fex-container-item">
              <span class="label">注册场所地址</span>
              <span class="value">{{fex.address}}</span>
            </p>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-table
      class="fex-list"
      :row-key="record => record.uuid"
      :data="data"
      :loading="loading"
    >
      <el-table-column label="申请时间"></el-table-column>
      <el-table-column label="开票类型"></el-table-column>
      <el-table-column label="发票抬头"></el-table-column>
      <el-table-column label="发票总额"></el-table-column>
      <el-table-column label="发票备注"></el-table-column>
      <el-table-column label="退票备注"></el-table-column>
      <el-table-column label="发票性质"></el-table-column>
      <el-table-column label="发票状态"></el-table-column>
      <el-table-column label="驳回原因"></el-table-column>
      <el-table-column label="操作"></el-table-column>
    </el-table>
    <el-pagination class="pagination"
                   :page-size="pagination.limit"
                   @current-change="handleNext"
                   layout="total,prev, pager, next"
                   :total="pagination.total"></el-pagination>
  </div>
</template>

<script>
  export default {
    name: 'org-basic-fex',
    components: {},
    mounted: function () {
    },
    data: function () {
      return {
        fex: {
          invoiceTitle: '苏州云咖信息系统集成服务有限公司',
          invoiceType: '增值税专用发票',
          invoiceNumber: '91320594MA21PJX0XK',
          bank: '中国银行苏州红旗支行',
          bankNumber: '548274828338',
          address: '江苏省苏州市工业园区东振路',
        },
        data: [],
        pagination: {
          current: 1,
          total: 5,
        },
        loading: false
      }
    },
    methods: {
      handleTableChange() {
      },
    },
  }
</script>

<style lang="scss" scoped>
  .fex {
    padding: $container-padding;

    &-container {
      height: 144px;

      &-title {
        @include font-medium();
        font-weight: 500;

        i {
          cursor: pointer;
        }

        i:hover {
          color: $theme-color;
        }
      }

      &-item {
        @include font-little();
        padding-left: $small-space;
        margin-top: $middle-space;

        .label {
        }

        .label:after {
          content: ":";
          display: inline-block;
          padding-left: 2px;
          width: 12px;
        }

      }

      &-price {
        @include font-large-s();
        color: $color-black;
        margin: $small-space 0;

        span {
          display: inline-block;
          margin-left: $small-space;
        }
      }

      &-remark {
        @include font-little();
        margin-bottom: $middle-space;
      }

      &-information {
        height: 100%;
        border-left: 1px dashed $content-border-color;
        padding-left: $middle-space;
      }

      &-money {
        padding-left: $middle-space;
      }
    }

    &-list {
      margin: $middle-space 0;

    }

    &-list  .ant-table{
      min-height: 240px !important;
    }

    &-list  .ant-empty-normal{
      margin: 128px 0 !important;
    }

  }
</style>
